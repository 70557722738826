@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.ant-btn:hover, 
.ant-btn:active, 
.ant-btn:focus {
  color: #9c9696;
}

::selection {
  background-color: #9c9696;
}

@primary-color: #ededed;@menu-item-active-bg: #bfbfbf;@link-hover-color: #9c9696;@link-active-color: #9c9696;